import axios from 'axios'
import { API_ENDPOINT } from '../../config'

const loadJournals = instance => {
    instance.setState({
        ...instance.state,
        loadingJournals: true
    })

    axios({
        method: 'post',
        url: API_ENDPOINT + "api/journal/journals",
        data: {
            offset: 0,
            limit: 100,
            criteria: {}
        }
    }).then(response => handleLoadJournalsResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadJournalsResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingJournals: false,
                journals: response.data.data.journals
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loadingJournals: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Worker = instance => {
    return {
        loadJournals: () => loadJournals(instance)
    }
}

export default Worker